.animate-ping-once {
    animation: ping .5s cubic-bezier(0, 0, 0.2, 1) 1 forwards;
}

@keyframes ping {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}