/* Define a color variable */
:root {
    --markerBackgroundColor: #54b989;
    --markerBorderRadius: 6px;
    --markerBackgroundColorSales: #54b989;
    --markerBackgroundColorRentals: #aa2eb0;
}

.rentals {
    --markerBackgroundColor: #aa2eb0;
    --clusterMarkerOutlineHeartOpacity: 100%;
    --clusterMarkerSolidHeartOpacity: 40%;
}

.sales {
    --markerBackgroundColor: #54b989;
    --outlineHeartOpacity: 30%;
    --solidHeartOpacity: 30%;
}

.map-marker-color-filled-heart {
    color: red;
}

.cluster-marker-outline-heart {
    opacity: var(--clusterMarkerOutlineHeartOpacity);
}

.cluster-marker-solid-heart {
    opacity: var(--clusterMarkerSolidHeartOpacity);
}

/* use that color */
.speech-bubble {
    position: absolute;
    background: var(--markerBackgroundColor);
    border: 2px solid white;
    border-radius: var(--markerBorderRadius);
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
    cursor: pointer;
    white-space: nowrap;
}
.speech-bubble::before {
    content: "";
    position: absolute;
    bottom: -12px; /* Adjust this value to move the tail up or down */
    left: 50%;
    width: 0;
    height: 0;
    border-left: 12px solid transparent; /* Adjust this value to change the size of the tail */
    border-right: 12px solid transparent; /* Adjust this value to change the size of the tail */
    border-top: 12px solid white; /* Adjust this value to change the size of the tail */
    transform: translateX(-50%);
}

.speech-bubble::after {
    content: "";
    position: absolute;
    bottom: -10px; /* Adjust this value to move the tail up or down */
    left: 50%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent; /* Adjust this value to change the size of the tail */
    border-right: 10px solid transparent; /* Adjust this value to change the size of the tail */
    border-top: 10px solid var(--markerBackgroundColor); /* Adjust this value to change the size of the tail */
    transform: translateX(-50%);
}

.speech-bubble.favorite::after {
    content: "\f004";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: red;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
}

.floating-dialog {
    position: absolute;
    width: 80%;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    background-color: white;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
}

@keyframes gradient-move {
    0% {
        background-position: 100%;
    }
    100% {
        background-position: 0;
    }
}